<template>
  <div class="table">
    <div class="table__header">
      <div
        class="table__cell table__header-item"
        v-for="item in header_items"
        :key="item.name"
        :class="item.name"
        :style="item.name === active_name ? active_style : ''"
        @click="
          $emit('change_sorted', item.name);
          set_active_style(item.name);
        "
      >
        <span class="table__header-title">{{ item.title }}</span>
        <div class="table__header-arrows">
          <div
            class="table__header-arrow table__header-top-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === 1,
            }"
          ></div>
          <div
            class="table__header-arrow table__header-bottom-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === -1,
            }"
          ></div>
        </div>
      </div>
      <div
        class="table__cell table__header-item table__header-item_monitor-owner"
      ></div>
    </div>
    <div class="table__data">
      <template v-for="item in items">
        <div class="table__row" :key="item.id">
          <template v-for="cell in Object.keys(item)">
            <div
              class="table__cell"
              :key="cell + '1'"
              v-if="show_column(cell)"
              @click="set_active_style(cell)"
              :class="cell"
              :style="cell === active_name ? active_style : ''"
            >
              <div class="table__cell-text">{{ item[cell] }}</div>
            </div>
          </template>
          <div class="table__cell table__cell_monitor-owner">
            <custom-btn
              class="button button_theme_white button_small"
              @click="go_to_status(item)"
              >{{ $t("monitoring_owner.headers.status") }}</custom-btn
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
export default {
  name: "monitoringOwnerTable",
  components: { CustomBtn },
  props: {
    header_items: Array,
    items: Array,
  },
  data() {
    return {
      active_name: "",
      active_style: "",
    };
  },
  methods: {
    show_column(cell) {
      if (this.header_items.length === 0) return true;

      let col = false;
      this.header_items.forEach((item) => {
        if (item.name === cell) {
          col = true;
        }
      });
      return col;
    },
    go_to_status(item) {
      this.$router.push({ name: "status", params: { id: item.hub_id } });
    },
    set_active_style(name) {
      let active_width = 0;
      let fn = false;

      let collection = document.getElementsByClassName(name);
      for (let item of collection) {
        if (
          item.scrollWidth > item.clientWidth &&
          item.scrollWidth > active_width
        ) {
          active_width = item.scrollWidth;
          fn = true;

          item.addEventListener("transitionend", this.set_overflow);
        }
      }

      if (fn) {
        this.active_name = name;
        this.active_style = `flex: 0 0 ${active_width}px;`;
      }
    },

    set_overflow() {
      this.active_style += "overflow: visible";
    },
  },
};
</script>

<style scoped></style>
