<template>
  <div class="body monitoring" v-if="!loading">
    <div class="monitoring__filter-row">
      <div class="monitoring__filter-block">
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('monitoring_owner.company')"
          name="companies_owner"
          :items="companies"
          @change="set_filter"
        ></select-filter>
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('monitoring_owner.city')"
          name="cities_owner"
          :items="city_filters"
          @change="set_filter"
        ></select-filter>
        <select-filter
          class="
            monitoring__filter-block-item
            monitoring__filter-block-item_before_btn
          "
          :title="$t('monitoring_owner.pos')"
          name="pos_owner"
          :items="pos_filters"
          :disabled="pos_disabled"
          @change="set_filter"
        ></select-filter>
        <select-filter
          class="
            monitoring__filter-block-item
            monitoring__filter-block-item_before_btn
          "
          :title="$t('monitoring.device_type')"
          name="device_type_owner"
          :items="device_filters"
          @change="set_filter"
        ></select-filter>
        <custom-btn
          class="monitoring__filter-block-button button button_theme_blue"
          @click="apply_filters"
        >
          {{ $t("monitoring_owner.apply_filters") }}</custom-btn
        >
        <span
          class="monitoring__filter-block-clear-btn"
          :class="{
            'monitoring__filter-block-clear-btn_disabled': disabled_clear_btn,
          }"
          @click="clear_filters"
        >
          {{ $t("monitoring_owner.clear_filters") }}</span
        >
      </div>
      <appearance-filter
        :title="$t('monitoring.appearance')"
        name="appearance_owner"
        :items="headers"
        @change="set_appearance_filter"
      ></appearance-filter>
    </div>
    <monitoring-owner-table
      :header_items="filters_header"
      :items="monitoring"
      @change_sorted="change_sorted"
    ></monitoring-owner-table>
  </div>
</template>

<script>
import SelectFilter from "@/components/recurringComponents/selectFilter";
import CustomBtn from "@/components/recurringComponents/customBtn";
import AppearanceFilter from "@/components/recurringComponents/appearanceFilter";
import MonitoringOwnerTable from "@/components/components/monitoringOwnerTable";
import moment from "moment";

export default {
  name: "monitoringOwner",
  components: {
    MonitoringOwnerTable,
    AppearanceFilter,
    CustomBtn,
    SelectFilter,
  },
  data() {
    return {
      headers: [
        {
          name: "customer_id",
          title: this.$i18n.t("monitoring_owner.headers.customer_id"),
          sorted: 1,
          enable: false,
        },
        {
          name: "legal_name",
          title: this.$i18n.t("monitoring_owner.headers.legal_name"),
          sorted: 0,
          enable: false,
        },
        {
          name: "company",
          title: this.$i18n.t("monitoring_owner.headers.company"),
          sorted: 0,
          enable: false,
        },
        {
          name: "city",
          title: this.$i18n.t("monitoring_owner.headers.city"),
          sorted: 0,
          enable: false,
        },
        {
          name: "hub_id",
          title: this.$i18n.t("monitoring_owner.headers.hub_id"),
          sorted: 0,
          enable: false,
        },
        {
          name: "device_type",
          title: this.$i18n.t("monitoring_owner.headers.device_type"),
          sorted: 0,
          enable: false,
        },
        {
          name: "pos",
          title: this.$i18n.t("monitoring_owner.headers.pos"),
          sorted: 0,
          enable: false,
        },
        {
          name: "comment",
          title: this.$i18n.t("monitoring_owner.headers.comment"),
          sorted: 0,
          enable: false,
        },
        {
          name: "timezone",
          title: this.$i18n.t("monitoring_owner.headers.timezone"),
          sorted: 0,
          enable: false,
        },
        {
          name: "errors",
          title: this.$i18n.t("monitoring_owner.headers.errors"),
          sorted: 0,
          enable: false,
        },
        {
          name: "last_sync",
          title: this.$i18n.t("monitoring_owner.headers.last_sync"),
          sorted: 0,
          enable: false,
        },
        {
          name: "device_fw_ver",
          title: this.$i18n.t("monitoring_owner.headers.device_fw_ver"),
          sorted: 0,
          enable: false,
        },
      ],
      monitoring_polling: null,
    };
  },
  created() {
    this.$store.dispatch("load_monitoring");
    this.poll_monitoring_data();
  },
  watch: {
    "$i18n.locale"() {
      this.refresh_header_titles_locale();
    },
    pos_disabled() {
      if (
        this.$store.getters.SELECTED_FILTERS.cities_owner.length === 0 &&
        this.$store.getters.SELECTED_FILTERS.companies_owner.length === 0
      ) {
        this.$store.getters.SELECTED_FILTERS.pos_owner = [];
      }
    },
    pos_filters(newValue) {
      this.selected_filters.pos_owner = this.selected_filters.pos_owner.filter(
        (x) => newValue.some((item) => item.value === x)
      );
    },
  },
  computed: {
    filters() {
      return this.$store.getters.FILTERS;
    },
    selected_filters() {
      return this.$store.getters.SELECTED_FILTERS;
    },
    pos_disabled() {
      return (
        this.$store.getters.SELECTED_FILTERS.cities_owner.length === 0 &&
        this.$store.getters.SELECTED_FILTERS.companies_owner.length === 0
      );
    },
    loading() {
      return this.$store.getters.LOADING_MONITORING;
    },
    monitoring() {
      return this.$store.getters.FILTERED_HUBS.map((hub) => {
        let device_type = hub.device_type?.includes("HUB")
          ? this.$t("device.type.hub")
          : this.$t("device.type.app");
        return {
          id: hub.id,
          customer_id: hub?.company.customer_id,
          legal_name: hub.company.legal_name,
          company: hub.company.name,
          city: hub.city,
          hub_id: hub.device_id,
          device_type: device_type,
          pos: hub.pos_name,
          address: hub.pos_address,
          comment: hub.comment,
          timezone: "UTC " + hub.tz,
          errors: hub.errors.count,
          last_sync: hub.last_sync
            ? moment.unix(Number(hub.last_sync)).format("DD/MM/YYYY HH:MM:SS")
            : "",
          device_fw_ver: hub.device_fw_ver,
        };
      });
    },
    companies() {
      return Array.from(
        new Set(
          this.$store.getters.FILTERED_COMPANIES.map(
            (hub) => hub.company.legal_name
          )
        )
      ).map((item) => {
        return { title: item, value: item, selected: false };
      });
    },
    city_filters() {
      return Array.from(
        new Set(this.$store.getters.FILTERED_CITIES.map((hub) => hub.city))
      ).map((item) => {
        return { title: item, value: item, selected: false };
      });
    },
    device_filters() {
      return [
        {
          value: "HUB",
          text: this.$t("device.type.hub"),
        },
        {
          value: "APP",
          text: this.$t("device.type.app"),
        },
      ].map((item) => {
        return { title: item.text, value: item.value, selected: false };
      });
    },
    pos_filters() {
      return Array.from(
        new Set(this.$store.getters.FILTERED_POSES.map((hub) => hub.pos_name))
      ).map((item) => {
        return { title: item, value: item, selected: false };
      });
    },
    disabled_clear_btn() {
      return (
        this.selected_filters.companies_owner.length === 0 &&
        this.selected_filters.cities_owner.length === 0 &&
        this.selected_filters.device_type_owner.length === 0 &&
        this.selected_filters.pos_owner.length === 0
      );
    },
    filters_header() {
      let temp = [];
      for (let header of this.headers) {
        if (
          this.$store.getters.FILTERS["appearance_owner"].includes(header.name)
        ) {
          temp.push(header);
        }
      }
      return this.$store.getters.FILTERS["appearance_owner"].length == 0
        ? this.headers
        : temp;
    },
  },
  methods: {
    poll_monitoring_data() {
      this.monitoring_polling = setInterval(() => {
        this.$store.dispatch("quiet_load_monitoring");
      }, 60000);
    },
    set_filter(filter) {
      this.$store.dispatch("set_filters", filter);
    },
    set_appearance_filter() {
      this.$store.dispatch("apply_appearance_owner_filters");
    },
    clear_filters() {
      if (!this.disabled_clear_btn) {
        this.$store.dispatch("clear_filters").then(() => {
          this.$store.dispatch("quiet_load_monitoring");
        });
      }
    },
    apply_filters() {
      this.$emit("apply_filter");
      this.$store.dispatch("apply_filters");
    },
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.monitoring.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    refresh_header_titles_locale() {
      for (let item of this.headers) {
        item.title = "";
        item.title = this.$t(`monitoring_owner.headers.${item.name}`);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.monitoring_polling);
  },
};
</script>

<style scoped></style>
