<template>
  <component :is="component" />
</template>

<script>
import adminMonitoring from "@/components/pages/monitoring";
import monitoringOwner from "@/components/pages/monitoringOwner";
export default {
  name: "monitoringPage",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$socket.readyState === 1) {
        vm.$socket.sendObj({
          route: to.name,
          token: localStorage.getItem("access_token"),
        });
      }
    });
  },
  components: {
    adminMonitoring,
    monitoringOwner,
  },
  data() {
    return {};
  },
  computed: {
    component() {
      if (this.$auth.check({ name: "superadmin" })) {
        return "monitoringOwner";
      } else {
        return "adminMonitoring";
      }
    },
  },
};
</script>

<style scoped></style>
