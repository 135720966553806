<template>
  <div class="select-filter" :class="{ filter__disabled: disabled }">
    <div class="select-filter__wrapper" @click="show_filters_list">
      <span class="select-filter__title">{{ title }}</span>
      <div class="select-filter__stuff">
        <img
          src="@/assets/select_filter_arrow.svg"
          alt=""
          :class="{ filter__disabled: disabled }"
        />
        <span class="select-filter__counter" v-show="counter !== 0">{{
          counter
        }}</span>
      </div>
    </div>
    <div class="select-filter__items-field" v-show="show">
      <div
        v-for="item in items_list"
        :key="item.title"
        @click="select(item)"
        class="select-filter__item"
      >
        <div class="select-filter__check-icon">
          <div :class="{ 'select-filter_selected-item': selected(item) }"></div>
        </div>
        <span class="select-filter__item-text">{{
          item.title ? item.title : $t("monitoring.not_specified")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectFilter",
  props: {
    title: String,
    items: {
      type: Array,
      default: () => [],
    },
    name: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    const onClickOutside = (e) =>
      (this.show = this.$el.contains(e.target) && this.show);
    document.addEventListener("click", onClickOutside);
    this.$on("hook:beforeDestroy", () =>
      document.removeEventListener("click", onClickOutside)
    );

    this.$parent.$on("clear_filter", this.clear);
    this.$parent.$on("apply_filter", this.apply_filter);
  },
  mounted() {},
  computed: {
    items_list() {
      return this.items;
    },
    counter() {
      return this.$store.getters.SELECTED_FILTERS[this.name].length;
    },
  },
  methods: {
    selected(item) {
      return this.$store.getters.SELECTED_FILTERS[this.name].includes(
        item.value
      );
    },
    show_filters_list() {
      if (this.disabled) {
        return;
      }
      this.show = !this.show;
    },
    select(item) {
      this.$store.dispatch("select_filter", {
        name: this.name,
        value: item.value,
      });
    },
    refund() {
      let temp = [];
      this.items_list.forEach((item) => {
        if (item.selected) temp.push(item.title);
      });
      this.$emit("change", { name: this.name, value: temp });
    },
    apply_filter() {
      this.refund();
    },
    clear() {},
  },
};
</script>

<style scoped></style>
